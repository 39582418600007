<template>
    <template-base>
        <h3>Ativação e desativação da geração de alarmes</h3>
        <div class="row">
            <div class="col-md-6 col-xl-4 py-2" v-for="alarme in alarmes" :key="alarme.nome">
                <div v-if="alarme.ativo === true" class="card tcs-card elevate-1 p-3">
                    <div class="active-indicator on">Ativado</div>
                    <div class="my-title mt-1">{{alarme.nome}}</div>
                    <button type="button" @click="toggle(alarme, false)" class="btn btn-secondary mt-3">Desativar</button>
                </div>
                <div v-else-if="alarme.ativo === false" class="card tcs-card elevate-1 p-3">
                    <div class="active-indicator off">Desativado</div>
                    <div class="my-title mt-1">{{alarme.nome}}</div>
                    <button type="button" @click="toggle(alarme, true)" class="btn btn-success mt-3">Ativar</button>
                </div>
                <div v-else class="card tcs-card elevate-1 p-3">
                    <div class="active-indicator" style="height: 24px;">
                        <tb-skeleton shape="rect" style="background-color: #dcdcdc; height: 16px; width: 5rem; margin-bottom: 2px;" theme="opacity"></tb-skeleton>
                    </div>
                    <div class="my-title mt-1">{{alarme.nome}}</div>
                    <tb-skeleton shape="radius" class="mt-3" style="background-color: #dcdcdc; height: 38px; width: 100%" theme="opacity"></tb-skeleton>
                </div>
            </div>
        </div>
    </template-base>
</template>

<script>
import TemplateBase from '../templates/Base';
import loginService from '../services/login';
import axios from 'axios';
import api from '@/api';

export default {
    components: {
        TemplateBase,
    },
    data () {
        return {
            alarmes: [{
                nome: 'Alertas de vazamento de gás',
                idClasseAlarme: 1,
                severidade: 2,
                ativo: undefined
            }, {
                nome: 'Alarmes de vazamento de gás',
                idClasseAlarme: 1,
                severidade: 1,
                ativo: undefined
            }, {
                nome: 'Alertas de dispositivo',
                idClasseAlarme: 2,
                severidade: 2,
                ativo: undefined
            }, {
                nome: 'Alarmes de dispositivo',
                idClasseAlarme: 2,
                severidade: 1,
                ativo: undefined
            }, {
                nome: 'Alertas de calibração',
                idClasseAlarme: 3,
                severidade: 2,
                ativo: undefined
            }, {
                nome: 'Alertas de manutenção',
                idClasseAlarme: 4,
                severidade: 2,
                ativo: undefined
            }]
        }
    },
    methods: {
        toggle (alarme, state) {
            if (!loginService.isSafe()) {
                this.$route.meta.alarme = {
                    idx: this.alarmes.indexOf(alarme),
                    ativo: state,
                };
                this.$router.push({
                    name: 'revalidar_login',
                    params: {
                        next: this.$route,
                    },
                });
                return;
            }

            if (!state) {
                // TODO: transformar em função auxiliar para evitar duplicação de código
                return this.$swal.fire({
                    title: 'Você tem certeza?',
                    text: 'Ao realizar esta ação, TODOS os alarmes ou alertas dessa classe de alarme deixarão de ser gerados pelo sistema.\nPara confirmar sua ação, digite "desativar alarmes" (sem as aspas) na campo abaixo.',
                    type: 'warning',
                    showCancelButton: true,
                    reverseButtons: true,
                    focusCancel: true,
                    confirmButtonColor: '#dc3545',
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar',
                    allowOutsideClick: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        let a = alarme.ativo;
                        alarme.ativo = undefined;
                        return axios.put(api.v1.statusAlarme.deactivate, {
                            idClasseAlarme: alarme.idClasseAlarme,
                            severidade: alarme.severidade,
                        }).then(res => {
                            alarme.ativo = res.data.ativo;
                            this.$snotify.success('Alarmes desativados. Esta mudança pode demorar até 1 minuto para surtir efeito.', 'Sucesso!');
                        }).catch(err => {
                            alarme.ativo = a;
                            this.$swal.showValidationMessage('Erro ao desativar alarmes para esta classe de alarmes');
                            console.trace(err);
                        })
                    },
                    input: 'text',
                    inputPlaceholder: 'Desativar alarmes',
                    inputValidator: (value) => {
                        if (value.toLowerCase() !== 'desativar alarmes') {
                            return 'Para confirmar a ação você precisa digitar "desativar alarmes" no campo acima.';
                        }
                    },
                });
            } else {
                let a = alarme.ativo;
                alarme.ativo = undefined;
                return axios.put(api.v1.statusAlarme.activate, {
                    idClasseAlarme: alarme.idClasseAlarme,
                    severidade: alarme.severidade,
                }).then(res => {
                    alarme.ativo = res.data.ativo;
                    this.$snotify.success('Alarmes ativados. Esta mudança pode demorar até 1 minuto para surtir efeito.', 'Sucesso!');
                }).catch(err => {
                    alarme.ativo = a;
                    this.$snotify.error('Erro ao desativar alarmes para esta classe de alarmes.', 'Erro');
                    console.trace(err);
                });
            }
        }
    },
    created () {
        if (this.$route.meta.alarme && loginService.isSafe()) {
            let alarme = this.alarmes[this.$route.meta.alarme.idx];
            this.toggle(alarme, this.$route.meta.alarme.ativo);
        }
        delete this.$route.meta.alarme;
        for (let alarme of this.alarmes) {
            axios.get(api.v1.statusAlarme.get(alarme.idClasseAlarme, alarme.severidade))
            .then(res => {
                alarme.ativo = res.data.ativo;
            })
            .catch(err => {
                this.$snotify('Por favor tente novamente atualizando a página.', 'Erro ao carregar');
                console.trace(err);
            });
        }
    }
};
</script>

<style lang="scss" scoped>

@keyframes opacity {
    from { opacity: 1; }
    to { opacity: 0; }
}

.active-indicator {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: gray;
    user-select: none;
    &::before {
        content: '';
        background-color: gray;
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 5px;
        margin-bottom: 2px;
    }

    &.on {
        color: green;
        font-weight: bold;
        &::before {
            background-color: green;
        }
    }
    &.off {
        color: darkred;
        font-weight: bold;
        &::before {
            background-color: darkred;
        }
    }
}
.my-title {
    font-size: 1.3rem;
}
</style>
